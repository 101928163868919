import React, { useState, useEffect } from 'react';

const Cursor = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [isPointer, setIsPointer] = useState(false);
  const [isCursor, setIsCursor] = useState(false);
  const [isTouch, setIsTouch] = useState(false);

  useEffect(() => {
    // Update cursor position
    const handleMouseMove = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    // Update cursor state based on tag
    const handleMouseOver = (e) => {
      setIsTouch(false);

      const tag = e.target.tagName.toLowerCase();
      if (['a', 'input', 'button', 'select', 'textarea'].includes(tag) && !isTouch) {
        setIsPointer(true);
        setIsCursor(true);
        setIsTouch(false);
      }
      else if (['a', 'input', 'button', 'select', 'textarea'].includes(tag) && isTouch) {
        setIsPointer(false);
        setIsCursor(false);
        setIsTouch(true);
      }
      else if (!(['a', 'input', 'button', 'select', 'textarea'].includes(tag)) && isTouch) {
        setIsPointer(false);
        setIsCursor(false);
        setIsTouch(true);
      }
      else if (!(['a', 'input', 'button', 'select', 'textarea'].includes(tag)) && !isTouch) {
        setIsPointer(false);
        setIsCursor(true);
        setIsTouch(false);
      }
    };


    const handleTouchMove = (e) => {
      setIsTouch(true);
      setIsCursor(false);
      setIsPointer(false);
    }

    const handleTouchStart = (e) => {
      setIsTouch(true);
      setIsCursor(false);
      setIsPointer(false);
    }

    const handleTouchEnd = (e) => {
      setIsTouch(true);
      setIsCursor(false);
      setIsPointer(false);
    }

    const handleTouchCancel = (e) => {
      setIsTouch(true);
      setIsCursor(false);
      setIsPointer(false);
    }

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchcancel', handleTouchCancel);
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseover', handleMouseOver);

    // Cleanup listeners on component unmount
    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
      document.removeEventListener('touchcancel', handleTouchCancel);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseover', handleMouseOver);
    };
  }, [isCursor, isPointer, isTouch]);

  return (
    <div
      className={isCursor ? (`cursor ${isPointer ? 'cursor-pointer' : 'cursor'}`) : (isTouch ? 'cursor-touch' : '')}
      style={{ left: cursorPosition.x, top: cursorPosition.y }}
    />
  );

};

export default Cursor;
