import React from 'react';
import { useParams } from 'react-router-dom';

import ContactData from '../data/pages/Contact.json';
import FooterData from '../data/components/Footer.json';

function Contact() {
    const { lang = 'en' } = useParams(); // Default to 'en' if no lang parameter is present
    const contactData = ContactData[lang] || ContactData['en']; // Fallback to English if `lang` is not available
    const footerData = FooterData[lang] || FooterData['en']; // Fallback to English if `lang` is not available

    //AJAX-POST function to POST form to Netlify and use a custom redirect
    const handleSubmit = (event) => {
        event.preventDefault(); //cancel default event

        let p = document.createElement("p");
        p.id = "successMessage";

        const myForm = event.target; //grab the form element with the handleSubmit
        const formData = new FormData(myForm); //Use FormData() class to transform form

        if (document.cookie.split(";").some((item) => item.trim().startsWith("name="))) { //check for cookies
            p.innerHTML = contactData["form_again"];

        }
        else {
            fetch("/", { //fetch the data to post them to netlify, if Promise can't fulfill an error will be shown.
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: new URLSearchParams(formData).toString(),
            })
                .catch((error) => alert(error));

            let fMail = formData.get("email"); //grab Mail attribute-value
            document.cookie = "name=" + fMail + ";max-age=3600"; //create cookie

            p.innerHTML = contactData["form_success"];
        }

        myForm.replaceWith(p); //replace form with p element
        window.scrollTo(0, 0); //Basically for mobile to scroll back to top

    };

    window.addEventListener("load", (event) => { //onload check for cookies on form

        let p = document.createElement("p");
        p.id = "successMessage";
        let myForm = document.getElementById("contactForm");

        if (document.cookie.split(";").some((item) => item.trim().startsWith("name="))) {

            p.innerHTML = contactData["form_again"]

            if (myForm != null) {
                myForm.replaceWith(p);
            }

        }

    });


    return (
        <div lang={lang} id='contact'>
            <section className="event"></section>
            <section className='content-spacing'>
                <div>
                    <h1>{contactData["let's_collaborate_header"]}</h1>
                    <p>{contactData["let's_collaborate"]}</p>
                </div>

            </section>
            <section>
                <div id='contact-message'>
                    <div>
                        <form id="contactForm" name="contact" method="POST" data-netlify="true" onSubmit={handleSubmit}>
                            <input type="hidden" name="form-name" value="contact" />
                            <h2>{contactData["send_us_a_message"]}</h2>
                            <label className="reqForm" htmlFor="name">{contactData["what's_your_name"]}</label>
                            <input type="text" name="name" placeholder={contactData["what's_your_name_placeholder"]} required />
                            <label className="reqForm" htmlFor="email">{contactData["where_can_we_reach_you"]}</label>
                            <input type="email" name="email" placeholder={contactData["where_can_we_reach_you_placeholder"]} required />
                            <label className="reqForm" htmlFor="message">{contactData["how_can_we_help_you"]}</label>
                            <textarea name="message" placeholder={contactData["how_can_we_help_you_placeholder"]} required></textarea>

                            <button type="submit" className="button-container">
                                {contactData["say_hello"]}
                                <div className='button-overlay'></div>
                            </button>
                        </form>
                    </div>
                    <div>
                        <div>
                            <h2>{footerData.email_us_header}</h2>
                            <a href={`mailto:${footerData.email}`}>{footerData.email}</a><br />
                        </div>
                        <div>
                            <h2>{footerData.where_we_hang_out_header}</h2>
                            <a href="https://maps.app.goo.gl/mewuy1ddTVqYYMcu8" target="_blank" rel="noreferrer">
                                <span dangerouslySetInnerHTML={{ __html: footerData.address }} />
                            </a>
                        </div>
                        <div>
                            <h2>{footerData.find_us_on_header}</h2>
                            <div>
                                <a href={footerData.links.facebook} target="_blank" rel="noreferrer">Facebook</a>,{" "}
                                <a href={footerData.links.instagram} target="_blank" rel="noreferrer">Instagram</a>,{" "}
                                <a href={footerData.links.linkedin} target="_blank" rel="noreferrer">LinkedIn</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Contact;