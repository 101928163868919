import React from 'react';
import { useParams } from 'react-router-dom';
import FooterData from '../data/components/Footer.json';

function Footer() {
    const { lang = 'en' } = useParams(); // Default to 'en' if no lang parameter is present
    const footerData = FooterData[lang] || FooterData['en']; // Fallback to English if `lang` is not available

    return (
        <footer>
            <div>
                {/* Dynamic Header */}
                <h1>{footerData.header}</h1>
                <img src="/media/map-europ-particules.png" alt="" />
            </div>
            <div className="footer-contact">
                <div className="footer-contact-text">
                    {/* Dynamic Text */}
                    <p>{footerData.text}</p>
                </div>
                <div className="footer-contact-links">
                    <div>
                        <div>
                            {/* Dynamic Email Header and Email */}
                            <h2>{footerData.email_us_header}</h2>
                            <a href={`mailto:${footerData.email}`}>{footerData.email}</a><br />
                        </div>
                        <div>
                            {/* Dynamic Location Header and Address */}
                            <h2>{footerData.where_we_hang_out_header}</h2>
                            <a href="https://maps.app.goo.gl/mewuy1ddTVqYYMcu8" target="_blank" rel="noreferrer">
                                <span dangerouslySetInnerHTML={{ __html: footerData.address }} />
                            </a>
                        </div>
                    </div>
                    <div>
                        <div>
                            {/* Dynamic Social Media Header and Links */}
                            <h2>{footerData.find_us_on_header}</h2>
                            <div>
                                <a href={footerData.links.facebook} target="_blank" rel="noreferrer">Facebook</a>,{" "}
                                <a href={footerData.links.instagram} target="_blank" rel="noreferrer">Instagram</a>,{" "}
                                <a href={footerData.links.linkedin} target="_blank" rel="noreferrer">LinkedIn</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
