// src/utils/importImages.js

function importMedia(subPath) {
    // Require all images from the static base path `/media`
    const context = require.context('../public', true, /\.(jpg)$/);

    const imagesMap = {}; // To temporarily hold images by base name
    const imagesArray = []; // To store the final array of images

    context.keys().forEach((key) => {
        // Only include images that are in the specified subPath
        if (!key.includes(subPath)) return;

        // Extract the base name of the image (without size and extension)
        const baseNameMatch = key.match(/(.+)_\d{3,4}w\.jpg$/);
        if (!baseNameMatch) return; // Skip if pattern doesn't match

        const baseName = baseNameMatch[1];

        // Extract the image width
        const widthMatch = key.match(/_(\d{3,4})w\.jpg$/);
        if (!widthMatch) return; // Skip if width pattern doesn't match

        const width = widthMatch[1];

        // Initialize the baseName entry if it does not exist
        if (!imagesMap[baseName]) {
            imagesMap[baseName] = {
                name: baseName,
                variants: {}
            };
        }

        // Store the image path with the corresponding width
        imagesMap[baseName].variants[width] = context(key);
    });

    // Convert the map to an array of images
    for (const baseName in imagesMap) {
        imagesArray.push(imagesMap[baseName]);
    }

    return imagesArray;
}

export default importMedia;
