import {
    WebGLRenderer,
    Scene,
    PerspectiveCamera,
    Vector3,
    HalfFloatType,
    BufferGeometry,
    BufferAttribute,
    ShaderMaterial,
    Points,
    Clock,
    Vector2,
    ReinhardToneMapping
} from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { GPUComputationRenderer } from "three/examples/jsm/misc/GPUComputationRenderer.js";
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js'
import logoPath from './VF_Logo_Vector.svg'
import gsap from 'gsap'
import { Pane } from 'tweakpane'

export default class Particles {
    constructor() {
        this.canvas = document.getElementById("particles")
        this.svgLoader = new SVGLoader()
        this.renderer = new WebGLRenderer({
        canvas: this.canvas,
        antialias: true,
        alpha: true
        })

        this.renderer.setClearColor(getComputedStyle(document.documentElement).backgroundColor, 0)
        this.renderer.setPixelRatio(window.devicePixelRatio)
        this.renderer.toneMapping = ReinhardToneMapping
        this.scene = new Scene()
        this.camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
        this.camera.position.z = 2

        this.renderPass = new RenderPass(this.scene, this.camera);
        this.bloomPass = new UnrealBloomPass(new Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);
        this.bloomPass.enabled = false;
        this.bloomPass.threshold = 0;
        this.bloomPass.strength = 0.5;
        this.bloomPass.radius = 0.2;

        this.composer = new EffectComposer(this.renderer)
        this.composer.addPass(this.renderPass)
        this.composer.addPass(this.bloomPass)

        this.clock = new Clock()
        this.clock.start()

        this.isMobile = window.innerWidth < 600

        this.render = this.isMobile ? () => this.renderer.render(this.scene, this.camera) : () => this.composer.render()
        this.width = this.isMobile ? 512 : 512

        this.mouse = new Vector3(0, 0, 1000)
        this.delta = 0

        this.position = new Vector3(0, 0, 0)
        this.scale = 3

        this.svg = null

        this.init()
        this.setSize()
        window.addEventListener("resize", this.setSize.bind(this))
    }

    init() {
        this.points = []
        this.svgLoader.load("" + logoPath, (data) => {
        const viewBox = data.xml.getAttribute('viewBox').split(' ').map((v) => parseFloat(v))
        const curves = data.paths[0].subPaths[0].curves
        const totalIterations = this.width * this.width / 10
        const iterationPerCurve = Math.floor(totalIterations / curves.length)
        const scale = { x: viewBox[2] - viewBox[0], y: viewBox[3] - viewBox[1] }
        curves.forEach((curve) => {
            for (let i = 0; i < iterationPerCurve; i++) {
                let t = i / iterationPerCurve
                let p = curve.getPoint(t)
                let point = new Vector3(p.x, p.y, 0)
                point.x = point.x / scale.x - 0.5
                point.y = (1 - point.y / scale.y) - 0.5
                p.z = (Math.random() - 0.5) * 10
                this.points.push(point)
            }
        })

        this.initParticles()
        this.initComputeRenderer()
        window.addEventListener("mousemove", this.onMouseMove.bind(this))
        window.addEventListener("touchmove", this.onTouchMove.bind(this))
        document.addEventListener("mouseleave", this.mouseExit.bind(this))
        window.addEventListener("touchend", this.mouseExit.bind(this))

        //this.initGui()
        this.setTransform(0, 0, 0, this.scale/1.5)
        this.animate()
        }, 
        // called when loading is in progresses
        function ( xhr ) {

            console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );

        },
        // called when loading has errors
        function ( error ) {

            console.log( 'An error happened' );

        })
    }

    setSize() {
        this.renderer.setSize(window.innerWidth, window.innerHeight)
        this.camera.aspect = window.innerWidth / window.innerHeight
        this.composer.setSize(window.innerWidth, window.innerHeight)
        this.camera.updateProjectionMatrix()
    }

    onMouseMove(e) {
        let x = (e.clientX / window.innerWidth - 0.5) * 20
        let y = -(e.clientY / window.innerHeight - 0.5) * 20

        this.updateMouseUniform(x, y)
    }

    onTouchMove(e) {
        let x = (e.touches[0].clientX / window.innerWidth - 0.5) * 20
        let y = -(e.touches[0].clientY / window.innerHeight - 0.5) * 20

        this.updateMouseUniform(x, y)
    }

    updateMouseUniform(x, y) {
        this.mouse.x = x
        this.mouse.y = y
        this.mouse.z = 0

        this.mouse.unproject(this.camera)

        this.mouse.z = 0

        this.velocityVariable.material.uniforms.mouse = { value: this.mouse };
        this.positionVariable.material.uniforms.mouse = { value: this.mouse };
    }

    mouseExit() {
        this.mouse.set(0, 0, 1000)
    }

    initGui() {
        this.pane = new Pane();
        this.pane.containerElem_.style.top = '20px'
        this.pane.containerElem_.style.left = '20px'
        this.pane.containerElem_.style.zIndex = '9999'
        const informationsPane = this.pane.addFolder({
        title: 'Informations',
        expanded: false
        })

        informationsPane.addBinding(this, 'width', {
        readonly: true,
        label: 'Particles Amount',
        format: (v) => (v * v).toFixed(0),
        });

        const particlesBehaviourPane = this.pane.addFolder({
        title: 'Particles Behavior',
        expanded: false,
        })
        particlesBehaviourPane.addBinding(this.velocityVariable.material.uniforms.u_noiseFreq, 'value', {
        label: 'Noise Frequency',
        min: 0.001,
        max: 10,
        });
        particlesBehaviourPane.addBinding(this.velocityVariable.material.uniforms.u_noiseForce, 'value', {
        label: 'Noise Force',
        min: 0.0001,
        max: 0.01,
        });
        particlesBehaviourPane.addBinding(this.velocityVariable.material.uniforms.u_mouseMaxDist, 'value', {
        label: 'Mouse Max Dist',
        min: 0.1,
        max: 10,
        });
        particlesBehaviourPane.addBinding(this.velocityVariable.material.uniforms.u_mouseForce, 'value', {
        label: 'Mouse Force',
        min: 0.0001,
        max: 0.1,
        });
        particlesBehaviourPane.addBinding(this.velocityVariable.material.uniforms.u_massDecay, 'value', {
        label: 'Mass Decay',
        min: 0.001,
        max: 1.,
        });
        particlesBehaviourPane.addBinding(this.velocityVariable.material.uniforms.u_massMin, 'value', {
        label: 'Mass Min',
        min: 0.001,
        max: 1.,
        });
        particlesBehaviourPane.addBinding(this.velocityVariable.material.uniforms.u_massMax, 'value', {
        label: 'Mass Max',
        min: 0.001,
        max: 10.,
        });
        particlesBehaviourPane.addBinding(this.velocityVariable.material.uniforms.u_drag, 'value', {
        label: 'Drag',
        min: 0.,
        max: 0.1,
        });

        const particlesAppearancePane = this.pane.addFolder({
        title: 'Particles Appearance',
        expanded: false,
        })

        particlesAppearancePane.addBinding(this.particles.material.uniforms.u_colorStart, 'value', {
        label: 'Color Start'
        });

        particlesAppearancePane.addBinding(this.particles.material.uniforms.u_colorEnd, 'value', {
        label: 'Color End'
        });

        particlesAppearancePane.addBinding(this.particles.material.uniforms.u_alpha, 'value', {
        label: 'Alpha',
        min: 0.,
        max: 1.,
        });

        particlesAppearancePane.addBinding(this.particles.material.uniforms.u_size, 'value', {
        label: 'Size',
        min: 0.,
        max: 8.,
        });

        const particlesPositionPane = this.pane.addFolder({
        title: 'Particles Position',
        expanded: false,
        })

        const positionBinding = particlesPositionPane.addBinding(this, 'position', {
        label: 'Position',
        min: -1,
        max: 1,
        step: 0.05,
        }).on('change', (v) => {
        this.position = v.value
        })

        const scaleBinding =  particlesPositionPane.addBinding(this, 'scale', {
        label: 'Scale',
        min: 0.1,
        max: 5,
        }).on('change', (v) => {
        this.scale = v.value
        })

        particlesPositionPane.addButton({
        title: 'Apply',
        }).on('click', () => {
        this.setTransform(this.position.x, this.position.y, this.position.z, this.scale)
        })
        
        particlesPositionPane.addButton({
        title: 'Reset',
        }).on('click', () => {
        this.position.set(0, 0, 0)
        this.scale = 1
        
        positionBinding.refresh()
        scaleBinding.refresh()

        this.setTransform(0, 0, 0, 1)
        })

        const bloomPane = this.pane.addFolder({
        title: 'Bloom',
        expanded: false,
        })

        bloomPane.addBinding(this.bloomPass, 'enabled', {
        label: 'Enabled'
        })

        bloomPane.addBinding(this.bloomPass, 'threshold', {
        label: 'Threshold',
        min: 0,
        max: 1,
        })
        bloomPane.addBinding(this.bloomPass, 'strength', {
        label: 'Strength',
        min: 0,
        max: 1,
        })
        bloomPane.addBinding(this.bloomPass, 'radius', {
        label: 'Radius',
        min: 0,
        max: 1,
        })
    }

    initParticles() {
        let geometry = new BufferGeometry();

        const positions = new Float32Array(this.width * this.width * 3);
        let p = 0;

        for (let i = 0; i < this.width * this.width; i++) {
        let p = this.points[i % this.points.length]
        positions[p++] = 0//p.x;
        positions[p++] = 0//p.y;
        positions[p++] = 0//p.z;
        }

        const uvs = new Float32Array(this.width * this.width * 2);
        p = 0;

        for (let j = 0; j < this.width; j++) {
        for (let i = 0; i < this.width; i++) {
            uvs[p++] = i / (this.width - 1);
            uvs[p++] = j / (this.width - 1);
        }
        }

        geometry.setAttribute('position', new BufferAttribute(positions, 3));
        geometry.setAttribute('uv', new BufferAttribute(uvs, 2));

        let particleUniforms = {
        'textureStartPosition': { value: null },
        'texturePosition': { value: null },
        'textureVelocity': { value: null },
        'mouse': { value: this.mouse },
        'u_alpha': { value: 0.1 },
        'u_size': { value: 2 },
        'u_colorStart': { value: {r: 255, g: 255, b: 255} },
        'u_colorEnd': { value: {r: 124, g: 176, b: 255} },
        };

        let vertexShader = /* glsl */ `
        // For PI declaration:
        #include <common>

        uniform sampler2D texturePosition;
        uniform sampler2D textureVelocity;
        uniform vec3 mouse;

        uniform float u_alpha;
        uniform float u_size;
        uniform vec3 u_colorStart;
        uniform vec3 u_colorEnd;

        varying vec4 vColor;

        void main() {
            vec3 pos = texture2D( texturePosition, uv ).xyz;
            vec4 velTemp = texture2D( textureVelocity, uv );
            vec3 vel = velTemp.xyz;
            float mass = velTemp.w;
            
            vec3 col = mix(u_colorEnd, u_colorStart, smoothstep(0.5, 1.5, mass)) / 255.;
            vColor = vec4(col, u_alpha);
            
            vec4 mvPosition = modelViewMatrix * vec4( pos, 1.0 );
            gl_PointSize = u_size * (mass / -mvPosition.z);
            gl_Position = projectionMatrix * mvPosition;
        }
        `

        let fragmentShader = /* glsl */ `
        varying vec4 vColor;

        void main() {
            float f = length( gl_PointCoord - vec2( 0.5, 0.5 ) );
            if ( f > 0.5 ) discard;
            gl_FragColor = vColor;
        }
        `

        let material = new ShaderMaterial({
        uniforms: particleUniforms,
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        transparent: true,
        });

        material.needsUpdate = true;
        material.extensions.drawBuffers = true;

        this.particles = new Points(geometry, material);
        this.particles.matrixAutoUpdate = false;
        this.particles.updateMatrix();

        this.scene.add(this.particles);
    }

    initComputeRenderer() {
        this.gpuCompute = new GPUComputationRenderer(this.width, this.width, this.renderer);

        if (this.renderer.capabilities.isWebGL2 === false) {
        this.gpuCompute.setDataType(HalfFloatType);
        }

        const dtStartPosition = this.gpuCompute.createTexture();
        const dtPosition = this.gpuCompute.createTexture();
        const dtVelocity = this.gpuCompute.createTexture();

        this.fillTextures(dtStartPosition, dtPosition, dtVelocity);

        let velocityShader = /* glsl */ `
        #include <common>

        uniform float time;
        uniform float delta;
        uniform vec3 mouse;

        uniform float u_noiseFreq;
        uniform float u_noiseForce;

        uniform float u_mouseForce;
        uniform float u_mouseMaxDist;

        uniform float u_massDecay;
        uniform float u_massMin;
        uniform float u_massMax;

        uniform float u_drag;


        const float width = resolution.x;
        const float height = resolution.y;

        //	Simplex 3D Noise 
        //	by Ian McEwan, Ashima Arts
        //
        vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
        vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}

        float snoise(vec3 v){ 
        const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
        const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);

        // First corner
        vec3 i  = floor(v + dot(v, C.yyy) );
        vec3 x0 =   v - i + dot(i, C.xxx) ;

        // Other corners
        vec3 g = step(x0.yzx, x0.xyz);
        vec3 l = 1.0 - g;
        vec3 i1 = min( g.xyz, l.zxy );
        vec3 i2 = max( g.xyz, l.zxy );

        //  x0 = x0 - 0. + 0.0 * C 
        vec3 x1 = x0 - i1 + 1.0 * C.xxx;
        vec3 x2 = x0 - i2 + 2.0 * C.xxx;
        vec3 x3 = x0 - 1. + 3.0 * C.xxx;

        // Permutations
        i = mod(i, 289.0 ); 
        vec4 p = permute( permute( permute( 
                    i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
                + i.y + vec4(0.0, i1.y, i2.y, 1.0 )) 
                + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));

        // Gradients
        // ( N*N points uniformly over a square, mapped onto an octahedron.)
        float n_ = 1.0/7.0; // N=7
        vec3  ns = n_ * D.wyz - D.xzx;

        vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)

        vec4 x_ = floor(j * ns.z);
        vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)

        vec4 x = x_ *ns.x + ns.yyyy;
        vec4 y = y_ *ns.x + ns.yyyy;
        vec4 h = 1.0 - abs(x) - abs(y);

        vec4 b0 = vec4( x.xy, y.xy );
        vec4 b1 = vec4( x.zw, y.zw );

        vec4 s0 = floor(b0)*2.0 + 1.0;
        vec4 s1 = floor(b1)*2.0 + 1.0;
        vec4 sh = -step(h, vec4(0.0));

        vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
        vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;

        vec3 p0 = vec3(a0.xy,h.x);
        vec3 p1 = vec3(a0.zw,h.y);
        vec3 p2 = vec3(a1.xy,h.z);
        vec3 p3 = vec3(a1.zw,h.w);

        //Normalise gradients
        vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
        p0 *= norm.x;
        p1 *= norm.y;
        p2 *= norm.z;
        p3 *= norm.w;

        // Mix final noise value
        vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
        m = m * m;
        return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1), 
                                        dot(p2,x2), dot(p3,x3) ) );
        }

        vec3 snoiseVec3( vec3 x ){

        float s  = snoise(vec3( x ));
        float s1 = snoise(vec3( x.y - 19.1 , x.z + 33.4 , x.x + 47.2 ));
        float s2 = snoise(vec3( x.z + 74.2 , x.x - 124.5 , x.y + 99.4 ));
        vec3 c = vec3( s , s1 , s2 );
        return c;

        }


        vec3 curlNoise( vec3 p ){

        const float e = .1;
        vec3 dx = vec3( e   , 0.0 , 0.0 );
        vec3 dy = vec3( 0.0 , e   , 0.0 );
        vec3 dz = vec3( 0.0 , 0.0 , e   );

        vec3 p_x0 = snoiseVec3( p - dx );
        vec3 p_x1 = snoiseVec3( p + dx );
        vec3 p_y0 = snoiseVec3( p - dy );
        vec3 p_y1 = snoiseVec3( p + dy );
        vec3 p_z0 = snoiseVec3( p - dz );
        vec3 p_z1 = snoiseVec3( p + dz );

        float x = p_y1.z - p_y0.z - p_z1.y + p_z0.y;
        float y = p_z1.x - p_z0.x - p_x1.z + p_x0.z;
        float z = p_x1.y - p_x0.y - p_y1.x + p_y0.x;

        const float divisor = 1.0 / ( 2.0 * e );
        return normalize( vec3( x , y , z ) * divisor );

        }
        vec2 spherize(vec2 p)
        {
            vec2 _p = p;
            p -= 0.5;
            p /= 0.5;
            float theta  = atan(p.y, p.x);
            float radius = length(p);
            float barrelPower = 1.;
            barrelPower *= 10.;
            radius = pow(radius, barrelPower);
            p.x = radius * cos(theta);
            p.y = radius * sin(theta);
            return mix(_p, 0.5 * (p + 1.0), smoothstep(0.2, 0.9, distance(_p, vec2(0.5))));
        }

        float easeOutBack(float x) {
            float c1 = 1.70158;
            float c3 = c1 + 1.;

            return 1. + c3 * pow(x - 1., 3.) + c1 * pow(x - 1., 2.);
        }

        void main()	{
            vec2 uv = gl_FragCoord.xy / resolution.xy;

            vec4 tmpPos = texture2D( texturePosition, uv );
            vec3 pos = tmpPos.xyz;

            vec4 tmpVel = texture2D( textureVelocity, uv );
            vec3 vel = tmpVel.xyz;
            float mass = tmpVel.w;

            if(mass > 0.){
                vec3 f = curlNoise( (pos + vec3(sin(time*0.5), time*0.6, cos(time*0.7)) * 0.1) * u_noiseFreq );
                f *= u_noiseForce;
                vel += f ;

                float dist = distance(mouse, pos);
                vec3 dir = normalize(mouse - pos);
                float force = (1. - smoothstep(0., u_mouseMaxDist, dist)) * u_mouseForce;
                vel += dir * force;
                
                mass -= delta * u_massDecay;
            }
            else{
                vel = vec3(0.);
                mass = u_massMin + rand(uv.xy) * (u_massMax - u_massMin);
            }

            vel *= 1. - u_drag;

            gl_FragColor = vec4( vel, mass );
        }
        `

        let positionShader = /* glsl */ `
        uniform float time;
        uniform float delta;

        uniform vec3 u_position;
        uniform float u_scale;

        void main() {
            vec2 uv = gl_FragCoord.xy / resolution.xy;

            vec4 startPos = texture2D( textureStartPosition, uv );

            vec4 tmpPos = texture2D( texturePosition, uv );
            vec3 pos = tmpPos.xyz;

            vec4 tmpVel = texture2D( textureVelocity, uv );
            vec3 vel = tmpVel.xyz;
            float mass = tmpVel.w;

            if ( mass <= 0. ) {
                vel = vec3( 0.0 );
                pos.x = startPos.x;
                pos.y = startPos.y;
                pos.z = startPos.z;

                pos *= u_scale;
                pos += u_position * 10.;
            }

            // Dynamics
            pos += vel * delta * (2. * mass + 0.5);
            
            gl_FragColor = vec4( pos, 1.0 );
        }
        `

        let startPositionShader = /* glsl */ `

        void main() {
            vec2 uv = gl_FragCoord.xy / resolution.xy;
            vec4 pos = texture2D( textureStartPosition, uv );
            gl_FragColor = pos;
        }
        `

        this.startPositionVariable = this.gpuCompute.addVariable('textureStartPosition', startPositionShader, dtStartPosition);
        this.positionVariable = this.gpuCompute.addVariable('texturePosition', positionShader, dtPosition);
        this.velocityVariable = this.gpuCompute.addVariable('textureVelocity', velocityShader, dtVelocity);

        this.velocityVariable.material.uniforms.time = { value: 0.0 };
        this.positionVariable.material.uniforms.time = { value: 0.0 };

        this.velocityVariable.material.uniforms.delta = { value: 0.0 };
        this.positionVariable.material.uniforms.delta = { value: 0.0 };

        this.velocityVariable.material.uniforms.mouse = { value: this.mouse };
        this.positionVariable.material.uniforms.mouse = { value: this.mouse };

        this.positionVariable.material.uniforms.u_position = { value: new Vector3(0, 0, 0) };
        this.positionVariable.material.uniforms.u_scale = { value: 1.0 };

        this.velocityVariable.material.uniforms.u_noiseFreq = { value: 1 };
        this.velocityVariable.material.uniforms.u_noiseForce = { value: 0.002 };
        this.velocityVariable.material.uniforms.u_mouseMaxDist = { value: 1. };
        this.velocityVariable.material.uniforms.u_mouseForce = { value: 0.005 };
        this.velocityVariable.material.uniforms.u_massDecay = { value: 0.25 };
        this.velocityVariable.material.uniforms.u_massMin = { value: 0.5 };
        this.velocityVariable.material.uniforms.u_massMax = { value: 2. };
        this.velocityVariable.material.uniforms.u_drag = { value: 0.03 };

        this.gpuCompute.setVariableDependencies(this.startPositionVariable, [this.startPositionVariable, this.positionVariable, this.velocityVariable]);
        this.gpuCompute.setVariableDependencies(this.positionVariable, [this.startPositionVariable, this.positionVariable, this.velocityVariable]);
        this.gpuCompute.setVariableDependencies(this.velocityVariable, [this.startPositionVariable, this.positionVariable, this.velocityVariable]);

        const error = this.gpuCompute.init();

        if (error !== null) {
        console.error(`GPU Compute Renderer Did Not Initialize: ${error}`);
        } else {
        console.warn(`GPU Compute Renderer Initialized: ${this.width * this.width} particles`)
        }

    }

    fillTextures(textureStartPosition, texturePosition, textureVelocity) {
        const posArray = texturePosition.image.data;
        const startPosArray = textureStartPosition.image.data;
        const velArray = textureVelocity.image.data;

        for (let k = 0, kl = posArray.length; k < kl; k += 4) {
        const i = k / 4 % this.points.length
        // Start Position
        startPosArray[k + 0] = this.points[i].x //x
        startPosArray[k + 1] = this.points[i].y//y
        startPosArray[k + 2] = this.points[i].z//z
        startPosArray[k + 3] = 0;

        // Position
        posArray[k + 0] = startPosArray[k + 0]
        posArray[k + 1] = startPosArray[k + 1]
        posArray[k + 2] = startPosArray[k + 2]
        posArray[k + 3] = 0;



        // Velocity
        velArray[k + 0] = 0;//x
        velArray[k + 1] = 0;//y
        velArray[k + 2] = 0;//z
        velArray[k + 3] = 1;//mass
        }
    }

    setTransform(x, y, z, s) {
        this.position.set(x, y, z)
        this.scale = s

        const pos = this.position.clone()
        pos.unproject(this.camera)
        pos.z = 0

        const startPos = this.positionVariable.material.uniforms.u_position.value
        const startScale = this.positionVariable.material.uniforms.u_scale.value

        const anim = {progress: 0}

        gsap.to(anim, {
        progress: 1,
        duration: 2,
        ease: 'power2.inOut',
        onUpdate: () => {
            const toPos = startPos.clone().lerp(pos, anim.progress)
            const toScale = startScale + (this.scale - startScale) * anim.progress

            this.positionVariable.material.uniforms.u_position.value = toPos
            this.positionVariable.material.uniforms.u_scale.value = toScale
        }
        })
    }

    animate() {
        requestAnimationFrame(this.animate.bind(this))

        let t = this.clock.getElapsedTime()
        this.delta = t - this.time
        this.time = t

        this.gpuCompute.compute()
        this.particles.material.uniforms['texturePosition'].value = this.gpuCompute.getCurrentRenderTarget(this.positionVariable).texture;
        this.particles.material.uniforms['textureVelocity'].value = this.gpuCompute.getCurrentRenderTarget(this.velocityVariable).texture;

        this.velocityVariable.material.uniforms.time.value = t;
        this.positionVariable.material.uniforms.time.value = t;
        this.velocityVariable.material.uniforms.delta.value = this.delta;
        this.positionVariable.material.uniforms.delta.value = this.delta;

        this.composer.render()
    }



    cleanup() {
        // Remove event listeners
        window.removeEventListener("resize", this.setSize.bind(this));
        window.removeEventListener("mousemove", this.onMouseMove.bind(this));
        window.removeEventListener("touchmove", this.onTouchMove.bind(this));
        document.removeEventListener("mouseleave", this.mouseExit.bind(this));
        window.removeEventListener("touchend", this.mouseExit.bind(this));
    
        // Dispose of Three.js objects
        if (this.particles) {
        this.particles.geometry.dispose();
        this.particles.material.dispose();
        this.scene.remove(this.particles);
        }
    
        if (this.gpuCompute) {
        this.gpuCompute.dispose();
        }
    
        if (this.composer) {
        this.composer.dispose();
        }
    
        if (this.pane) {
        this.pane.dispose();
        }
    
        // Dispose of renderer and other resources
        if (this.renderer) {
        this.renderer.dispose();
        }
    }
}