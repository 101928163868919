import React, { useEffect, useRef, useState } from 'react';
import Particles from '../js/particles'; // Ensure the path is correct

const ParticleBackground = () => {
    const canvasRef = useRef(null);
    const particlesInstanceRef = useRef(null);
    const [showSettings, setShowSettings] = useState(false);
    const konamiCode = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65]; // Up, Up, Down, Down, Left, Right, Left, Right, B, A
    const keySequence = useRef([]);
    let spawned = useRef(false);

    useEffect(() => {
        
        if (!spawned.current) {
            spawned.current = true;
        
            if (canvasRef.current) {
                // Initialize Particles if not already initialized
                if (!particlesInstanceRef.current) {
                    particlesInstanceRef.current = new Particles(canvasRef.current);
                }
            }
        }

        // Cleanup function to be called when the component unmounts
        return () => {
            if (particlesInstanceRef.current) {
                particlesInstanceRef.current.cleanup();
                particlesInstanceRef.current = null; // Clean up the reference
            }
        };
    }, []); // Empty dependency array means this effect runs only once on mount and unmount

    useEffect(() => {
        const handleKeyDown = (event) => {
            keySequence.current.push(event.keyCode);

            // Check if the sequence matches the Konami code
            if (keySequence.current.toString().includes(konamiCode.toString())) {
                setShowSettings(true);
                keySequence.current = []; // Reset sequence after successful match
            } else if (keySequence.current.length > konamiCode.length) {
                keySequence.current.shift(); // Keep the sequence length within the range of Konami code
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    });

    const closeSettings = () => {
        setShowSettings(false);
    };

    return (
        <>
            <canvas
                ref={canvasRef}
                id="particles"
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    pointerEvents: 'none',
                    zIndex: '-1'
                }}
            />
            {showSettings && (
                <div className='logoSettings rounded'>
                    <div>
                        <h2>Particle Settings</h2>
                        <span onClick={closeSettings} >
                            ✕
                        </span>
                    </div>
                    
                    
                    <p>Configure your settings here.</p>
                    {/* Add your settings components here */}
                </div>
            )}
        </>
    );
};

export default ParticleBackground;
