import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import importMedia from '../importMedia';
import importVideos from '../importVideos';
import ResponsiveImage from '../components/ResponsiveImage';

function Project() {
    const { lang = 'en', project } = useParams(); // Get language and project parameters from the URL
    const [projectData, setProjectData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Dynamically import the JSON file based on the project parameter
        import(`../data/projects/${project}.json`)
            .then((data) => {
                // Set the project data based on the selected language
                setProjectData(data[lang] || data['en']);
            })
            .catch((err) => {
                console.error('Error loading project data:', err);
                setError('Project not found');
            });
    }, [lang, project]);

    if (error) {
        return <div>{error}</div>;
    }

    if (!projectData) {
        return <div>Loading...</div>; // Show a loading state while fetching the data
    }

    const { title, client, format, services, phrase, synopsis, details } = projectData;

    const mainImage = importMedia(`/media/projects/${project}/Processed/Main`);
    const libImages = importMedia(`/media/projects/${project}/Processed/Library`);
    const videos = importVideos(`/media/projects/${project}/Processed/Videos`);
    console.log(videos);

    return (
        <div lang={lang} id="project" data-menu="project">
            <div id="project-header">
                <div>
                    <h1>{title}</h1>
                    <div id="project-subtitle">
                        <ul className='unstyledList'>
                            <li><b>Client:</b> {client}</li>
                            <li><b>Format:</b> {format.join(', ')}</li>
                            <li><b>Services:</b> {services.join(', ')}</li>
                        </ul>
                        <p>
                            <i>{phrase}</i>
                        </p>
                    </div>
                </div>
            </div>
            <div id="project-sections" className='zigzag2-desktop'>
                <section>
                    <div className="project-synopsis">
                        <div>
                            <h1>_Synopsis</h1>
                            <div>
                                {synopsis.map((paragraph, index) => (
                                    <p key={index}>{paragraph}</p>
                                ))}
                            </div>
                        </div>
                        <ul className='unstyledList project-detail'>
                            {Object.entries(details).map(([key, value]) => (
                                <li key={key}><b>{key}:</b> {Array.isArray(value) ? value.join(', ') : value}</li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        {/* Import Main Image here */}
                        {mainImage && (
                            <ResponsiveImage image={mainImage[0]} sizes='(min-width: 780px) calc(60vw - 160px), calc(100vw - 96px)' />
                        )}
                    </div>
                </section>

                {/* Generate sections with library images */}
                {libImages.map((image, index) => {
                    if (index % 2 === 0) {
                        // Start a new section for every two images
                        return (
                            <section key={index} className='content-spacing'>
                                <div>
                                    <ResponsiveImage image={image} />
                                </div>
                                {/* Check if there is a next image to display */}
                                {libImages[index + 1] && (
                                    <div>
                                        <ResponsiveImage image={libImages[index + 1]} sizes='(min-width: 780px) calc(60vw - 160px), calc(100vw - 96px)' />
                                    </div>
                                )}
                            </section>
                        );
                    }
                    return null; // Skip if it's the second image of the pair
                })}
            </div>
            <div id="project-video-sections" >
                {videos.map((video, index) => {
                    return (
                        <section key={index}>
                            <video controls src={video.path} type="video/mp4" className='rounded' />
                        </section>
                    );
                })}
            </div>
        </div>
    );
}

export default Project;
