import React from 'react';
import { useParams } from 'react-router-dom';
import Carousel from '../components/Carousel';

function Home() {
  const { lang = 'en' } = useParams(); // Default to 'en' if no lang parameter is present

  return (
    <div lang={lang} id='home'>
      <Carousel />
    </div>
  );
}

export default Home;