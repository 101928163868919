import React from 'react';

function NoPage() {
    return (
        <div>
            <h1>404</h1>
            <p>Page not found!</p>
        </div>
    );
}

export default NoPage;