import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

import Home from './pages/Home';
import Projects from './pages/Projects';
import Distribution from './pages/Distribution';
import About from './pages/About';
import Contact from './pages/Contact';
import Team from './pages/Team';
import NoPage from './pages/404';

import Project from './pages/Project';

import Cursor from './components/Cursor'; // Adjust the path as necessary

function App() {
    return (
        <BrowserRouter>
            <Cursor />
            <div className="App">
                {/* Header will always be shown and will change based on language */}
                <Routes>
                    <Route path=":lang/*" element={<Header />} />
                    {/* Redirect to /en/ if no language is specified */}
                    <Route path="*" element={<Navigate to="/en/" replace />} />
                </Routes>

                <main>
                    <Routes>
                        {/* Define routes for each language */}
                        <Route path=":lang/" element={<Home />} />
                        <Route path=":lang/projects" element={<Projects />} />
                        <Route path=":lang/distribution" element={<Distribution />} />
                        <Route path=":lang/about" element={<About />} />
                        <Route path=":lang/team" element={<Team />} />
                        <Route path=":lang/contact" element={<Contact />} />
                        <Route path=":lang/projects/:project" element={<Project />} />
                        <Route path=":lang/*" element={<NoPage />} />
                    </Routes>
                </main>

                {/* Footer should also be updated dynamically based on the language parameter */}
                <Routes>
                    <Route path=":lang/*" element={<Footer />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
