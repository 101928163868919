// src/utils/importVideos.js

function importVideos(subPath) {
    // Require all video files from the static base path `/media`
    const context = require.context('../public', true, /\.(mp4|webm)$/);
  
    const videosMap = {}; // To temporarily hold videos by base name
    const videosArray = []; // To store the final array of videos
  
    context.keys().forEach((key) => {
      // Only include videos that are in the specified subPath
      if (!key.includes(subPath)) return;
  
      // Extract the base name of the video (without extension)
      const baseNameMatch = key.match(/(.+)\.(mp4|webm)$/);
      if (!baseNameMatch) return; // Skip if pattern doesn't match
  
      const baseName = baseNameMatch[1];
  
      // Initialize the baseName entry if it does not exist
      if (!videosMap[baseName]) {
        videosMap[baseName] = {
          name: baseName,
          path: context(key) // Store the video path
        };
      }
    });
  
    // Convert the map to an array of videos
    for (const baseName in videosMap) {
      videosArray.push(videosMap[baseName]);
    }
  
    return videosArray;
  }
  
  export default importVideos;
  